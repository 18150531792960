import { CSSProperties, ReactElement, useState } from 'react';
import theme from '../theme';
import { Divider, Text, Tooltip } from '@chakra-ui/react';
import {
  Home,
  Search,
  Heart,
  Bookmark,
  Settings,
  LogOut,
  HelpCircle,
  MessageSquare,
} from 'react-feather';
import { color, motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useAuth } from 'oidc-react';
import { incrementRefreshCounter } from '../lib/state/slices/ViewSlice';
import { ChevronLeftIcon } from '@chakra-ui/icons';

function SidebarButton(props: {
  icon?: ReactElement;
  collapsed: boolean;
  text: string;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  tooltip?: string;
}) {
  const textVariants = {
    collapsed: {
      opacity: 0,
      display: 'none',
    },
    expanded: {
      opacity: 1,
      display: 'inherit',
    },
    disabled: {
      cursor: 'not-allowed',
    },
  };

  function selectTextStyle() {
    const res = [];
    props.collapsed ? res.push('collapsed') : res.push('expanded');
    props.disabled ? res.push('disabled') : res.push('');
    return res;
  }
  const variants = {
    active: {
      backgroundColor: theme.colors.purple[100],
    },

    inactive: {
      backgroundColor: theme.colors.gray[100],
    },
    collapsed: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: '22px',
      paddingLeft: '32px',
      paddingBottom: '22px',
      paddingRight: '32px',
      flexDirection: 'column',
    },
    expanded: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingTop: '22px',
      paddingLeft: '32px',
      paddingBottom: '22px',
      paddingRight: '32px',
    },
    disabled: {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  };
  const selectStyle = () => {
    const res = [];
    props.active ? res.push('active') : res.push('inactive');
    props.collapsed ? res.push('collapsed') : res.push('expanded');
    props.disabled ? res.push('disabled') : res.push('');
    return res;
  };
  return (
    <Tooltip label={props.disabled ? 'Coming soon' : null}>
      <motion.div
        style={{ borderRadius: '10px' }}
        initial={props.active ? 'active' : 'inactive'}
        animate={selectStyle()}
        variants={variants}
      >
        <div
          style={{
            ...props.style,
            display: 'flex',
            flexDirection: props.collapsed ? 'column' : 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={props.disabled ? () => {} : props.onClick}
        >
          {props.icon ? props.icon : null}
          <motion.div
            variants={textVariants}
            style={{ width: '100%' }}
            initial="expanded"
            animate={selectTextStyle()}
          >
            <Text
              marginLeft="10px"
              maxWidth={'300px'}
              fontSize="medium"
              fontWeight={600}
              textAlign="center"
              whiteSpace={'nowrap'}
              userSelect={'none'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {props.text}
            </Text>
          </motion.div>
        </div>
      </motion.div>
    </Tooltip>
  );
}

export default function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const auth = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const graphViews = useAppSelector((state) => state.viewReducer.graphViews);
  const logoVariants = {
    collapsed: {
      marginLeft: '0px',
      marginRight: '0px',
      marginTop: '11px',
      alignSelf: 'center',
    },
    expanded: {
      marginRight: '0px',
      marginTop: '11px',
      alignSelf: 'center',
    },
  };
  const variants = {
    collapsed: {
      width: '90px',
    },
    expanded: {
      width: '400px',
    },
  };
  return (
    <motion.div
      variants={variants}
      initial="collapsed"
      animate={collapsed ? 'collapsed' : 'expanded'}
      style={{ overflow: 'clip' }}
    >
      <div
        style={{
          width: '100%',
          height: '100vh',
          backgroundColor: theme.colors.gray[100],
          display: 'flex',
          flexDirection: 'column',
          padding: '2px',
          alignItems: 'center',
        }}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          variants={logoVariants}
          initial="expanded"
          animate={collapsed ? 'collapsed' : 'expanded'}
        >
          <Tooltip label="click to expand/minimize" bgColor="green" placement="right">
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <img
                src="/logo512.png"
                alt="logo"
                style={{
                  marginLeft: '18px',
                  marginRight: '8px',
                  width: '59px',
                  height: '59px',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  setCollapsed(!collapsed);
                  dispatch(incrementRefreshCounter());
                }}
              />

              <ChevronLeftIcon
                style={{ color: 'grey', width: '45px', height: '50px' }}
                onClick={() => {
                  setCollapsed(!collapsed);
                  dispatch(incrementRefreshCounter());
                }}
              ></ChevronLeftIcon>
            </div>
          </Tooltip>
        </motion.div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginTop: '40px',
          }}
        >
          <SidebarButton
            key={'home'}
            collapsed={collapsed}
            icon={<Home />}
            text="Homepage"
            active={location.pathname === '/' || location.pathname === '/research'}
            onClick={() => {
              navigate('/research');
            }}
          />
          <SidebarButton
            key={'search'}
            collapsed={collapsed}
            icon={<Search />}
            text="Search"
            active={location.pathname === '/search'}
            onClick={() => {
              navigate('/search');
            }}
          />
          <SidebarButton
            collapsed={collapsed}
            icon={<Heart />}
            text="Recommended Cases"
            disabled={true}
            active={location.pathname === '/recommended_cases'}
            onClick={() => {
              navigate('/recommended_cases');
            }}
          />
          <SidebarButton
            collapsed={collapsed}
            icon={<Bookmark />}
            text="Saved Cases"
            active={location.pathname === '/saved_cases'}
            onClick={() => {
              navigate('/saved_cases');
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginTop: '40px',
            maxHeight: '250px',
          }}
        >
          <Text
            fontSize="small"
            fontWeight={700}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            paddingRight={'10px'}
            paddingLeft={'32px'}
          >
            Previously viewed Graphs
          </Text>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
            }}
          >
            {graphViews.map((view, index) => {
              return (
                <SidebarButton
                  style={{ textOverflow: 'ellipsis', padding: '0px !important' }}
                  collapsed={false}
                  text={view.name}
                  onClick={() => {
                    navigate(`/graph/${index}`);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Divider
            style={{ width: '80%', margin: 20, borderWidth: '2px', opacity: '90%' }}
            color="gray"
            width={1}
            orientation="horizontal"
          ></Divider>
          <SidebarButton
            collapsed={collapsed}
            style={{ color: 'purple' }}
            icon={<MessageSquare />}
            text="Give us your feedback!"
            onClick={() => {
              window.open('https://nettskjema.no/a/463244')?.focus();
            }}
            disabled={false}
          />
          <SidebarButton
            collapsed={collapsed}
            icon={<HelpCircle />}
            text="Learn More"
            onClick={() => {
              navigate('/learn_more');
            }}
          />
          <SidebarButton
            collapsed={collapsed}
            icon={<Settings />}
            text="Settings"
            onClick={() => {
              window
                .open(
                  `https://auth.meshr.org/realms/${process.env.REACT_APP_AUTH_REALM}/account/`,
                  '_blank',
                )
                ?.focus();
            }}
            disabled={false}
          />
          <SidebarButton
            collapsed={collapsed}
            icon={<LogOut />}
            text="Log out"
            onClick={() => {
              auth.signOutRedirect();
            }}
          />
        </div>
      </div>
    </motion.div>
  );
}
