import { useEffect, useState } from 'react';
import EdgeArrowProgram from 'sigma/rendering/webgl/programs/edge.arrow';
import { addEdgeToGraph, addNodeToGraph, graphFromData } from '../../lib/GraphFactory';
import {
  ControlsContainer,
  SearchControl,
  SigmaContainer,
  ZoomControl,
  useLoadGraph,
  useSigma,
} from '@react-sigma/core';
import '@react-sigma/core/lib/react-sigma.min.css';
import NodeTooltip from './NodeTooltip';
import GraphEvents from './Events';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import TimeFilter from './controls/TimeFilter';
import NodeContextMenu from './controls/NodeContextMenu';
import { useGetNeighboursQuery, useGetRecommendedNeighboursQuery } from '../../lib/api/GraphAPI';
import { store } from '../../lib/state/Store';
import Filter from './controls/Filter';
import SearchControlWrapper from './SearchControlWrapper';
import LoadingScreen from '../LoadingScreen';
import { Button, IconButton, List, Text } from '@chakra-ui/react';
import { removeFloatingDoc, setToolOpenStatus } from '../../lib/state/slices/ViewSlice';
import FloatingDocument from './FloatingDocument';
import DocumentCard from '../../components/DocumentCard';
const Header = (props: { graphName: string, isListView: boolean, toggleView: () => void }) => {
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  return (
    <div style={{ width: '100%', padding: '20px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Text fontWeight={800} fontSize={'x-large'}>
          {props.graphName}
        </Text>
      </div>
      <div>
        <Button marginLeft="12px" colorScheme="purple" variant="outline" onClick={props.toggleView}>
          {/* todo: fix the placement of buttons, rn: hardcoded not good */}
          {props.isListView ? 'View as graph' : 'View as list'}
        </Button>
        <Button marginLeft={'10px'} type="button" colorScheme="purple" onClick={() => store.dispatch(setToolOpenStatus({ ...toolOpenStatus, advancedFilters: !toolOpenStatus.advancedFilters }))}>
          Filter
        </Button>
      </div>
    </div>
  );
};

const LoadNeighbours = (props: { id: string }) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: props.id,
    level: 1,
  });

  const sigma = useSigma();
  const graph = sigma.getGraph();

  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
        }
      }
      for (let i = 0; i < data.edges.length; i++) {
        if (!graph.hasEdge(data.edges[i].elementId)) {
          let edge = data.edges[i];
          addEdgeToGraph(edge, graph);
        }
      }
    }
  }, [props.id, data]);

  return null;
};

const RecommendNeighbours = (props: { id: string; nodeId: string }) => {
  const { data, error, isLoading, isSuccess } = useGetRecommendedNeighboursQuery(props.id);

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
          // Set color based on distance
          graph.addUndirectedEdge(props.nodeId, node.elementId, {
            distance: data.distances[i],
            color: 'orange',
            size: 2 * (1 - data.distances[i]),
          });
        }
      }
    }
  }, [props.id, data]);

  return null;
};

const LoadGraph = (props: { data: any; centerNode: string }) => {
  const loadGraph = useLoadGraph();
  const refreshCounter = useAppSelector((state) => state.viewReducer.refreshCounter);
  const sigma = useSigma();

  useEffect(() => {
    loadGraph(
      graphFromData({
        data: props.data,
        centerNode: props.centerNode,
      }),
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      sigma.refresh();
      sigma.resize();
    }, 300);
    sigma.refresh();
    sigma.resize();
  }, [refreshCounter]);

  return null;
};

export const GraphLayout = (props: { graphViewIndex: number; isActive?: boolean }) => {
  const dispatch = useAppDispatch();
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: store.getState().viewReducer.graphViews[props.graphViewIndex].centerNode,
    level: 1,
  });

  const [isListView, setIsListView] = useState(false);
  const toggleView = () => setIsListView((prev) => !prev);

  const [loadNeighbours, setLoadNeighbours] = useState<string>();
  const [recommendNeighbours, setRecommendNeighbours] = useState<{ id: string; nodeId: string }>();
  const [hoveredNode, onHover] = useState<{
    nodeId: string;
    id: string;
  }>();
  const [nodeContextMenu, setNodeContextMenu] = useState<{
    nodeId: string;
    id: string;
  }>();

  let floatingDocuments = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewIndex].floatingDocuments,
  );
  const openTools = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (data && isSuccess) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header graphName={store.getState().viewReducer.graphViews[props.graphViewIndex].name} toggleView={toggleView} isListView={isListView} />
        <style>
          {`
            .sigma-container {
              background-color: #E2E8F0;
              flex-grow: 1;
            }
          `}
        </style>
        {isListView ? (
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto' }}>
            <Text fontWeight={800} fontSize="x-large" paddingBottom="20px">
              List of all cases in this graph
            </Text>
            <List spacing={'8px'} width={'100%'} alignItems={'center'} display={'flex'} flexDirection={'column'} >
              {data.nodes.map((node) => (
                <DocumentCard key={node.properties.id} id={node.properties.id} ></DocumentCard>
              ))}
            </List>
          </div>
        ) : (
          <SigmaContainer
            style={{ height: '100%', width: '100%', display: 'flex' }}
            settings={{
              hoverRenderer: () => {},
              defaultEdgeType: 'arrow',
              edgeProgramClasses: { arrow: EdgeArrowProgram },
            }}
          >
            <LoadGraph
              data={data} centerNode={store.getState().viewReducer.graphViews[props.graphViewIndex].centerNode}
            />
            <GraphEvents
              onHoverNode={onHover}
              graphViewIndex={props.graphViewIndex}
              isActive={props.isActive}
              setNodeContextMenu={setNodeContextMenu}
            />
            <ControlsContainer position="top-right">
              <SearchControlWrapper>
                <SearchControl />
              </SearchControlWrapper>
            </ControlsContainer>
            {floatingDocuments.map((doc) => (
            <FloatingDocument elementId={doc.nodeId} id={doc.id} onClose={() => { dispatch(removeFloatingDoc({ graphViewIndex: props.graphViewIndex, floatingDocId: doc.id })); }} />
          ))}
            <TimeFilter style={{ position: 'absolute', bottom: '20px', left: '30%' }} graphViewIndex={props.graphViewIndex} />
            <Filter style={{ position: 'absolute', top: '20px', right: '20px', width: '300px' }} graphViewIndex={props.graphViewIndex} />
            {/* <QuickFilter style={{ position: 'absolute', right: '20px', bottom: '20px' }} graphViewIndex={props.graphViewIndex} /> */}
            {/* <Timeline style={{ position: "absolute", left: timelinePosition?.x, top: timelinePosition?.y }} /> */}
            {nodeContextMenu ? (
                <NodeContextMenu
                  id={nodeContextMenu.id}
                  nodeId={nodeContextMenu.nodeId}
                  graphViewIndex={props.graphViewIndex}
                loadNeighbours={(id: string) => {
                  setLoadNeighbours(id);
                }}
                recommendNeighbours={(id: string, nodeId: string) => {
                  setRecommendNeighbours({ id, nodeId });
                }}
              />
            ) : null}

            {loadNeighbours ? <LoadNeighbours id={loadNeighbours} /> : null}
            {recommendNeighbours ? <RecommendNeighbours id={recommendNeighbours.id} nodeId={recommendNeighbours.nodeId} /> : null}
          </SigmaContainer>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Error loading the Graph
      </div>
    );
  }
};
