import { useSigma } from "@react-sigma/core";
import DocumentCard from "../../components/DocumentCard";
import { useEffect, useState } from "react";

export default function FloatingDocument(props: { elementId: string, id: string, onClose: () => void }) {

    let sigma = useSigma()
    const [position, setPosition] = useState(() => {
        let attributes = sigma.getGraph().getNodeAttributes(props.elementId)
        let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y })
        viewportPosition.x = viewportPosition.x - 200
        viewportPosition.y = viewportPosition.y + 10
        return viewportPosition
    })
    useEffect(() => {
        sigma.on('afterRender', () => {
            let attributes = sigma.getGraph().getNodeAttributes(props.elementId)
            let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y })
            viewportPosition.x = viewportPosition.x - 200
            viewportPosition.y = viewportPosition.y + 10
            setPosition(viewportPosition)
        })

    }, [props.elementId])

    return <DocumentCard style={{ position: 'absolute', top: position.y, left: position.x }} id={props.id} onClose={props.onClose} />
}